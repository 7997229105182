/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Users.unshift(item);
  },
  SET_Users(state, users) {
    state.Users = users;
  },

  UPDATE_Users(state, user) {
    //debugger;
    const Index = state.Users.findIndex(p => p.ID == user.ID);
    if (Index != -1) {
      Object.assign(state.Users[Index], user);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Users.findIndex(p => p.ID == itemId);
    state.Users.splice(ItemIndex, 1);
  },
  SET_ROLES(state, roles) {
    debugger
    state.RoleUserPermissionList = roles;
  },
};
