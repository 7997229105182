/*=========================================================================================
  File Name: moduleVideo.js
  Description: Video Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleVideoState.js'
import mutations from './moduleVideoMutations.js'
import actions from './moduleVideoActions.js'
import getters from './moduleVideoGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
