/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
//import { domain} from '@/gloabelConstant.js'
export default {
  addItem({ commit }, item) {
    //debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("api/Account/Registration", item)
        .then(response => {
          //debugger
          resolve(response);
          commit("ADD_ITEM", Object.assign(item, { ID: response.data.ID }));
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }, model) {
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + "api/Account/GetAllUsers", model)
        .then(response => {
          resolve(response);
          commit("SET_Users", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .get("Api/Account/GetUser?userID=" + itemid)
        .then(response => {
          if (response.status == 200) {
            resolve(response);
            commit("UPDATE_Users", response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/Account/UpdateUser", item)
        .then(response => {
          resolve(response);
          commit("UPDATE_Users", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  HardResetPassword(context, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Account/HardResetPassword?userid=" + item.userID + "&newPassword="+item.newPassword)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeItem({ commit }, item) {
    //debugger;
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Account/DeleteUser?userID=" + item.ID)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetUserIstallment(itemID) {
    //debugger
    return new Promise((resolve, reject) => {
      axios
        .get("api/Account/GetUserIstallment", itemID)
        .then(response => {
          //commit('SET_Installments', response.data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  CheckIsRegister(commit, model) {
    //debugger
    return new Promise((resolve, reject) => {
      axios
        .post(
          "api/Account/CheckIsRegister?phoneNumber=" +
          model.PhoneNumber +
          "&UserLanguageID=" +
          model.UserLanguageID
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  CheckCode(commit, model) {
    //debugger;
    return new Promise((resolve, reject) => {
      axios
        .post(
          "api/Account/CheckCode?phoneNumber=" +
          model.Telephone +
          "&Code=" +
          model.VertificationCode
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  removeRelatedAccount(context, Obj) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          "api/Account/RemoveUserAccountRelated?ID=" +
          Obj.ID +
          "&userType=" +
          Obj.UserType
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  
  getAllRolePermission({ commit }) {
    debugger
    return new Promise((resolve, reject) => {
      axios.get("api/Role/GetAllRoles")
        .then((response) => {
          commit('SET_ROLES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

 
  GetGroupOfAdminRoles({ commit }) {
    debugger
    return new Promise((resolve, reject) => {
      axios.get("GetGroupOfAdminRoles")
        .then((response) => {
          commit('SET_ROLES', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },




};
