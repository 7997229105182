<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in Videos"
        :key="item.Video.ID"
        @click="$router.push(item.Video.url).catch(() => {})"
      >
        <vx-card class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-col">
            <video
              :width="350"
              :height="200"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + item.Video.URL" type="video/mp4" />
            </video>
          </div>
           <div class="vx-col mb-3 mt-3">
              <h4 class="mb-2">
                {{ item.AppUser.ApplicationUser.FullName }}
              </h4>
            </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import moduleVideo from "@/store/Video/moduleVideo.js";
import { domain } from "@/gloabelConstant.js";

export default {
  components: {

  },

  data() {
    return {
      search: {
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
      },
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    VideoSearch() {
      debugger;
      return this.$store.state.VideoList.VideoSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Videos() {
      return this.$store.state.VideoList.Videos;
    },
  },

  methods: {
    VideoDataSearch() {
      debugger;
      //this.VideoSearch.AppUserID =1
      const ID = this.$route.params.ID;

      this.$store
        .dispatch("VideoList/GetAllAppUserVideosFavoriteForUSer", ID)
        .then((res) => {
          if (
            document.documentElement.scrollHeight <= window.innerHeight &&
            res.data.Data.length >= this.VideoSearch.PageSize
          ) {
            this.VideoSearch.PageNumber++;
            this.VideoDataSearch();
          }
        });
    },
    VideoSearchFirst() {
      debugger;
      this.VideoSearch.PageNumber = 1;
      this.VideoDataSearch();
    },

  },
  destroyed() {
    window.onscroll = () => {};
  },
  created() {
    if (!moduleVideo.isRegistered) {
      this.$store.registerModule("VideoList", moduleVideo);
      moduleVideo.isRegistered = true;
    }

    this.$store.commit("VideoList/SET_Video", []);
    this.VideoSearchFirst();
  },
};
</script>

<style lang="scss">
