<template>
  <div id="page-user-edit">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All Users</router-link
        >
      </span>
    </vs-alert>

    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs class="tab-action-btn-fill-conatiner">
          <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <MainInfo class="mt-4" :AppUserModel="AppUserModel" />
            </div>
          </vs-tab>

          <vs-tab :label="$t('UserVideo')" icon-pack="feather" icon="icon-share-2">
             <div class="tab-text">
                <AppUserVideos
                  class="mt-4"
                  :VideoModel="VideoModel"
                />
              </div>
          </vs-tab>

          <vs-tab :label="$t('AppUserFavirotVideos')" icon-pack="feather" icon="icon-share-2">
             <div class="tab-text">
                <AppUserFavirotVideos
                  class="mt-4"
                  :VideoModel="VideoModel"
                />
              </div>
          </vs-tab>

          <!-- Save & Reset Button -->
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  class="ml-4 mt-2"
                  @click="save_changes"
                  :disabled="isFormValid"
                  >{{ $t("Save") }}
                </vs-button>
                <vs-button
                  class="ml-4 mt-2"
                  type="border"
                  color="danger"
                  @click="cancle"
                  >{{ $t("Cancel") }}</vs-button
                >
              </div>
            </div>
          </div>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>
<script>
import moduleUser from "@/store/user/moduleUser.js";
import moduleAppUser from "@/store/user/appuser/moduleAppUser.js";
import MainInfo from "./MainInfo.vue";
import AppUserVideos from "./AppUserVideos.vue";
import AppUserFavirotVideos from "./AppUserFavirotVideos.vue";

export default {
  components: {
    MainInfo,
    AppUserVideos,
    AppUserFavirotVideos,
  },
  computed: {},
  data() {
    return {
      AppUserModel: {
        ApplicationUser:{}
      },
      user_not_found: false,
      activeTab: 0,
    };
  },
  methods: {
    // reset_data() {
    //   this.initValues();
    // },
    cancle() {
      this.$router.push("/User/AppUser");
    },
    initValues() {
      this.AppUserModel = {};
    },
    save_changes() {
      debugger;
      if (this.AppUserModel.ID !== null && this.AppUserModel.ID >= 0) {
        this.$vs.loading();
        this.$store
          .dispatch("AppUserList/updateItem", this.AppUserModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            window.showSuccess();
            this.$router.push({ name: "AppUser" });

            this.initValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showError();
          });
      }
    },
  },
  created() {
    if (!moduleAppUser.isRegistered) {
      this.$store.registerModule("AppUserList", moduleAppUser);
      moduleAppUser.isRegistered = true;
    }
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined) {
      this.$store.dispatch("AppUserList/GetItemByID", ID).then((res) => {
        this.AppUserModel = res.data.data;
      });
    }
  },
};
</script>
