/*=========================================================================================
  File Name: moduleVideoActions.js
  Description: Video Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddVideo({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Video/AddVideo", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  AddVideoRate(context, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Video/AddVideoRate", item)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetAllVideos({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Video/GetAll")
        .then((response) => {
          commit('SET_Video', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  SearchVideo({ commit },search) {
    return new Promise((resolve, reject) => {
      axios.post("api/Video/SearchVideo",search)
        .then((response) => {
          commit('SET_Video', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateVideo(context, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Video/UpdateVideo", item)
        .then((response) => {
          // commit('UPDATE_Video', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetVideo(context, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Video/GetVideo?ID="+ itemid)
        .then((response) => {
          debugger
          // commit('UPDATE_Video', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteVideo({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Video/DeleteVideo?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteVideoComment(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Video/DeleteVideoComment?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteVideoLike(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Video/DeleteVideoLike?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteVideoRate(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Video/DeleteVideoRate?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteVideoFavorit(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Video/DeleteVideoFavorit?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllAppUserVideosFavoriteForUSer({ commit }, item){
    debugger;
    return new Promise((resolve, reject) => {
      axios.post("api/Video/GetAllAppUserVideosFavoriteForUSer?AppUserID="+ item)
        .then((response) => {
          commit('SET_Video', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
