<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="MainInfo">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-2/5">


          <div class="vx-row w-full">

            <div class="vx-col lg:w-1/2" >
              <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
              <vs-input
                v-model="AppUserModel.ApplicationUser.FullNameEn"
                class="w-full"
                :data-vv-as="$t('FullName')"
                name="FullName"

              />
            </div>

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
              <vs-input
                v-model="AppUserModel.ApplicationUser.FullNameAr"
                class="w-full"
                :data-vv-as="$t('FullName')"
                name="FullName"

              />
            </div>

            <div class="vx-col lg:w-1/2" >
              <label class="text-sm opacity-75">{{ $t("Address") }}</label>
              <vs-input
                v-model="AppUserModel.ApplicationUser.Address"
                class="w-full"
                :data-vv-as="$t('FullName')"
                name="FullName"

              />
            </div>

            <div class="vx-col lg:w-1/2" >
              <label class="text-sm opacity-75">{{ $t("Email") }}</label>
              <vs-input
                v-model="AppUserModel.ApplicationUser.Email"
                class="w-full"
                name="Email"
                :data-vv-as="$t('Email')"
                v-validate="'required'"
              />
            </div>

            <div class="vx-col lg:w-1/2" >
              <label class="text-sm opacity-75">{{ $t("PhoneNumber") }}</label>
              <vs-input
                v-model="AppUserModel.ApplicationUser.PhoneNumber"
                class="w-full"
                :data-vv-as="$t('PhoneNumber')"
                name="PhoneNumber"

              />
            </div>
     <!--
            <div v-if="!AppUserModel.ID > 0" class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("Password") }}</label>
              <vs-input
                v-model="AppUserModel.ApplicationUser.Password"
                type="password"
                :data-vv-as="$t('password')"
                class="w-full"
                name="password"
                v-validate="
                  'required|min:6|max:25|lowCase|UpCase|SpecialChar|number'
                "
                :disabled="AppUserModel.ID > 0"
              />
            </div>
            <span class="text-danger text-sm" v-show="errors.has('password')">{{
              errors.first("password")
            }}
            </span> -->

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("Active") }}</label>
              <vs-checkbox
                v-model="AppUserModel.ApplicationUser.IsActive"
                :value="true"
              ></vs-checkbox>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";

export default {
  components: {

  },
  props: {
    AppUserModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseURL: domain,
      isMail: false,
      DownUrl: domain,
      Attachment: null,
      update: false,
      AppUserStatus: [],
    };
  },
  computed: {},
  methods: {
    SetAppUserStatus() {
      if (localStorage.getItem("SaveLang") === "ar") {
        this.AppUserStatus = [
          { label: "نشط", value: true },
          { label: "غير نشط", value: false },
        ];
      } else {
        this.AppUserStatus = [
          { label: "Active", value: true },
          { label: "Inactive", value: false },
        ];
      }
    },
  },
  created() {
    this.SetAppUserStatus();
    //debugger;
    const ID = this.$route.params.ID;
    if (ID != undefined && !this.update) {
      this.$store.dispatch("AppUserList/GetItemByID", ID).catch((err) => {
        if (err.response.status === 404) {
          this.user_not_found = true;
          return;
        }
      });
    }
  },
};
</script>
